import oidcConfig from 'Persistence/oidc'

const { tokenKey } = oidcConfig

type OidcToken = {
	access_token: string
	expires_at: number
	scope: string
	token_type: string
}

function getToken(): OidcToken {
	const token = sessionStorage.getItem(tokenKey)

	if (!token) throw new Error('No token found')

	return JSON.parse(token)
}

export default {
	getToken
}
