import { UserManager, WebStorageStateStore } from 'oidc-client-ts'

const tokenKey = `oidc.user:${process.env.REACT_APP_API_IDENTITY_SERVER}:app-frontend`

const state = new WebStorageStateStore({ store: window.sessionStorage })

const tokenChannel = new BroadcastChannel('token_channel')

tokenChannel.onmessage = event => {
	if (event.data && event.data.user) {
		sessionStorage.setItem(tokenKey, event.data.user)
	}
}

const oidc = new UserManager({
	authority: `${process.env.REACT_APP_API_IDENTITY_SERVER}`,
	client_id: 'app-frontend',
	redirect_uri: `${process.env.REACT_APP_REDIRECT_URL}/post-login`,
	post_logout_redirect_uri: `${process.env.REACT_APP_POST_LOGOUT_URI}`,
	loadUserInfo: true,
	response_type: 'code',
	scope: 'hub offline_access',
	userStore: state,
	silent_redirect_uri: `${process.env.REACT_APP_REDIRECT_URL}/silent-redirect`,
	automaticSilentRenew: true
})

oidc.events.addAccessTokenExpiring(() => {
	console.log('Token is about to expire, calling silent renewal...')
	oidc.signinSilent()
})

oidc.events.addSilentRenewError(error =>
	console.log('Unable to renew token: ', error)
)

// Add event listener for token acquired
oidc.events.addUserLoaded(user => {
	if (user && user.access_token) {
		const serializedUser = JSON.stringify(user)
		sessionStorage.setItem(tokenKey, serializedUser)
		tokenChannel.postMessage({ user: serializedUser })
	}
})

export default { oidc, tokenKey }
