import React from 'react'
import oidcConfig from 'Persistence/oidc'
import { useTranslation } from 'react-i18next'

const { oidc } = oidcConfig

function Logout() {
	const { t } = useTranslation()

	oidc.signoutRedirect()

	return <h1 className="signing-text">{t('Signing out')}...</h1>
}

export default Logout
