import React, { useMemo } from 'react'
import oidcConfig from 'Persistence/oidc'
import { Redirect } from 'react-router-dom'
import RootStore from 'Store/Root/RootStore'
import beforeRootLoad from 'Store/Root/beforeRootLoad'

const { oidc } = oidcConfig

function PrivateApp() {
	const App = useMemo(
		() =>
			React.lazy(async () => {
				const token = await oidc.getUser()

				if (!token) {
					try {
						await oidc.signinSilent()
					} catch (e) {
						return { default: () => <Redirect to="/login" /> }
					}
				}

				await beforeRootLoad()
				return import('Web/App')
			}),
		[]
	)

	return (
		<RootStore.Provider>
			<App />
		</RootStore.Provider>
	)
}

export default PrivateApp
