import React from 'react'
import oidcConfig from 'Persistence/oidc'
import { Redirect } from 'react-router-dom'

const { oidc } = oidcConfig

const SilentRedirect = React.lazy(async () => {
	try {
		await oidc.signinSilentCallback()

		return { default: () => <Redirect to="/" /> }
	} catch (e) {
		return { default: () => <Redirect to="/login" /> }
	}
})

export default SilentRedirect
