import React from 'react'
import oidcConfig from 'Persistence/oidc'
import { useTranslation } from 'react-i18next'

const { oidc } = oidcConfig

function Login() {
	const { t } = useTranslation()

	oidc.clearStaleState()
	oidc.signinRedirect()

	return <h1 className="signing-text">{t('Signing in')}...</h1>
}

export default Login
